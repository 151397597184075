$primary-color: #737791

.root
  display: flex
  border-radius: 2px
  width: fit-content
  margin: 0 auto
  box-sizing: content-box
  .plus, .minus
    background-color: #ede7f2
    color: $primary-color
    height: 30px
    aspect-ratio: 1/1
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    user-select: none
    font-weight: 600
    border-radius: 8px

  .amount
    width: 40px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    border: none !important
    outline: none !important
    color: $primary-color
    font-size: 18px
    font-weight: 600
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
