$padding-horizontal: 32px
$padding-horizontal-mobile: 24px
$border-color: #DFDFDF
$background-color: #FAFBFC

@mixin left-content
    max-width: 350px
    min-width: 300px
    width: 35%

@mixin right-content
    width: 100%

.root
    background-color: $background-color
    height: 100dvh
    width: 100dvw
    overflow: hidden
    .sidebar
        @include left-content
        border-right: 1px solid $border-color
        padding: 24px $padding-horizontal
        overflow: auto
    .content
        @include right-content
        max-width: 1084px
        height: 100%
        overflow: auto
        padding: 32px $padding-horizontal 40px

@media screen and (max-width: 1200px)
    .root
        .sidebar
            display: none
        .content
            padding: 64px $padding-horizontal-mobile 40px
        .contentCart
            padding: 0

@media screen and (max-width:400px)
    .root
        .sidebar
            display: none
        .content
            .root
        .sidebar
            display: none
        .content
            padding: 10px 10px 40px
